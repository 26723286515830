import { render, staticRenderFns } from "./ChatGPT.vue?vue&type=template&id=8e57fa68&scoped=true&"
import script from "./ChatGPT.vue?vue&type=script&lang=js&"
export * from "./ChatGPT.vue?vue&type=script&lang=js&"
import style0 from "./ChatGPT.vue?vue&type=style&index=0&id=8e57fa68&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e57fa68",
  null
  
)

export default component.exports